class Auth {
  constructor () {
    this.user = {}
    this.token = ''
    this.storage = window.localStorage

    this.getFromStorage()
  }

  setUser (data) {
    this.user = data
    this.updateStorage()
    return this
  }

  setToken (token) {
    this.token = token
    this.updateStorage()
    return this
  }

  setStorage (name) {
    this.storage = window.localStorage
    window.localStorage.setItem('auth.storage', 'local')
    return this
  }

  updateStorage () {
    this.storage.setItem('auth', JSON.stringify({ user: this.user, token: this.token }))
    this.storage.setItem('auth._token.local', this.token)
  }

  saveAuth ({ user, token }) {
    this.user = user
    this.token = token
  }

  getFromStorage () {
    const authLocal = this.storage.getItem('auth') ? JSON.parse(this.storage.getItem('auth')) : null
    if (authLocal) { this.saveAuth(authLocal) }
    return authLocal
  }

  get User () {
    if (this.user && this.user.id) { return this.user }
    if (this.getFromStorage() && this.getFromStorage().user) { return this.getFromStorage().user }
    return null
  }

  get Token () {
    if (this.token) { return this.token }
    if (this.getFromStorage() && this.getFromStorage().token) { return this.getFromStorage().token }
    return null
  }

  get loggedIn () {
    return !!(this.Token)
  }

  reset () {
    this.user = {}
    this.token = ''
    this.updateStorage()
  }

  logOut () {
    this.reset()
    window.localStorage.removeItem('auth')
    window.localStorage.removeItem('auth._token.local')
    window.localStorage.removeItem('subData')
    window.localStorage.removeItem('currentSubscriptions')
    window.localStorage.removeItem('newPaymentMethod')
    window.localStorage.removeItem('stripePaymentMethod')
    window.localStorage.removeItem('paymentIntent')
  }
}

export default new Auth()
