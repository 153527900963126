import { getIp } from "~/fc/Client";
import AuthManager from "~/fc/Auth/AuthManager";
import {
  getInturruptedJobIds,
  retryFailedJobs,
} from "~/fc/Converter/JobHelper";
import { INTERRUPTED_JOBS_CHANNEL } from "../constants";

export const listenForPackageUpdate = async () => {
  const user = $nuxt.$auth.User;
  if (getInturruptedJobIds().length > 0) {
    $nuxt.$store.commit("banner/setUpgradingMidConversion", true);

    const bc = new BroadcastChannel(INTERRUPTED_JOBS_CHANNEL);
    const inturruptedJobsCount = getInturruptedJobIds().length;
    bc.onmessage = (event) => {
      if (event.data === "how_many_interrupted_jobs") {
        bc.postMessage(inturruptedJobsCount);
        bc.close();
      }
    };
  }

  if (user) $nuxt.$socket.emit("subscribe", `subscription_user.${user.id}`);
  else {
    const ip = await getIp();
    $nuxt.$socket.emit("subscribe", `subscription_userip.${ip}`);
  }
};

export const handleUserSubscriptionUpdate = async () => {
  const authManager = new AuthManager($nuxt);
  const token = localStorage.getItem("auth._token.local");
  await new Promise((r) => setTimeout(r, 2000));
  if (token) authManager.softLogIn({ token: token });
  $nuxt.$store.commit("banner/clearAllBanners");
  $nuxt.$store.commit("banner/setUpgradingMidConversion", false);
  retryFailedJobs();
};
